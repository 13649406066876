import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

// Colors
const blue = '#5A5BD4';
const blueDark = '#4f4fd8';

// Parameters
const buttonsBorderRadius = 20;
const buttonPadding = '5px 15px';
const inputBorderRadius = 10;

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 320,
            sm: 600,
            md: 900,
            lg: 1180,
            xl: 1920,
        },
    },
    palette: {
        background: {
            dark: '#2F4858',
            gray: '#EBEBEB',
            gradient: 'linear-gradient(65.54deg, #FE7563 12.26%, #FE7663 20.52%, #FE9368 83.36%)',
            default: colors.common.white,
            paper: colors.common.white,
            green: '#98FFAF',
            red: '#FF8282',
            greenLight: '#329A81',
        },
        borders: {
            green: '#81DF96',
            red: '#EB6969',
            gray: '#CFCFCF',
        },
        primary: {
            main: '#FFFFFF',
            light: colors.common.white,
        },
        secondary: {
            main: '#CFCFCF',
            light: colors.common.white,
        },
        c: {
            main: '#FFFFFF',
            background: '#FFFFFF',
            light: colors.common.white,
            contrastText: colors.common.white,
        },
        text: {
            main: '#656565',
            primary: '#656565',
            secondary: colors.blueGrey[600],
            black: 'black',
            darkGray: '#4D4D4D',
            bttnGray: '#575757',
            lightBlue: '#2C67DA',
            gray: '#666666',
            lightGray: '#BDBFD8',
            green: '#3C7129',
            red: '#E02B2F',
            white: '#FFFFFF',
            yellow: '#FC902D',
            orange: '#C1854D',
        },
    },
    components: {
        // Inputs
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid #115293`,
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid #115293`,
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-root.Mui-focused': {
                        color: '#115293',
                    },
                },
            },
        },
    },
    shadows,
    typography,
});

export default theme;
