import Link from 'next/link';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    title: {
        color: '#606060',
        marginRight: 'auto',
        fontSize: '14px',
        fontFamily: 'roboto',
        fontWeight: 'normal',
    },
    active: {
        color: '#EAEAEA',
        backgroundColor: '#EAEAEA',
        '& $title': {
            color: '#BDBFD8',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: '#BDBFD8',
        },
    },
}));

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
    const classes = useStyles();

    return (
        <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
            <Link legacyBehavior href={href} passHref>
                <Button activeclassname={classes.active} className={classes.button}>
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <Typography className={classes.title}>{title}</Typography>
                </Button>
            </Link>
        </ListItem>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItem;
