import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';

import IconButton from '@mui/material/IconButton';

// store
import { GlobalContext } from '/context';

// components
import DrawerMenu from './DrawerMenu';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.gray,
    },
}));

export default function AdminLayout({ children }) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const { state } = useContext(GlobalContext);
    const router = useRouter();

    useEffect(() => {
        if (!state.auth.loggedIn) {
            router.push('/');
            return;
        }
    }, [state.auth]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                onClick={handleDrawerOpen}
            ></AppBar>
            {/* <IconButton onClick={handleDrawerOpen}>Open Drawer</IconButton> */}

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                open={open}
                anchor="left"
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <DrawerMenu />
            </Drawer>
            <main className={classes.content}>{children}</main>
        </div>
    );
}
