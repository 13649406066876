import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function MainLayout({ children }) {
    const classes = useStyles();

    return (
        <div>
            <main>
                <div className={classes.root}>{children}</div>
            </main>
        </div>
    );
}
