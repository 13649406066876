import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Box, Divider, List, Typography, Button } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

// import Drawer from '@mui/material/Drawer';
import { GlobalContext } from '/context';

import DrawerMenuItem from './DrawerMenuItem';
import menus from '/constants/navigations';

// actions
import { logout } from '/context/actions/auth';

// assets
import Logo from '/public/home/logo2.png';

import { LogOut as LogoutIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
    name: {
        color: 'black',
        fontWeight: 'bold',
        paddingTop: '10px',
        fontSize: '16px',
    },
    navbar: {
        width: 200,
        margin: 'auto',
        backgroundColor: 'white',
        borderRadius: 6,
    },
    avatar: {
        cursor: 'pointer',
        marginTop: '40px',
        width: 96,
        height: 101,
    },
}));

const DrawerMenu = (props) => {
    const classes = useStyles();
    const router = useRouter();
    const { state, dispatch } = useContext(GlobalContext);

    // const [positions, setState] = useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    // });

    // const toggleDrawer = (anchor, open) => (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }

    //     setState({ ...positions, [anchor]: open });
    // };

    const handleLogout = async () => {
        await logout(dispatch);
        if (state.auth.loggedIn) router.push('/login');
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" className={classes.navbar}>
            <Box
                p={2}
                alignItems="center"
                display="flex"
                flexDirection="column"
                fetchpriority="high"
            >
                <Image src={Logo} width="104" height="104" alt="" priority fetchpriority="high" />
                <Typography className={classes.name} variant="h5">
                    {state.auth.loggedIn && state.auth.user.name}
                </Typography>
            </Box>
            <Divider />
            <Box p={2} className={classes.navbar}>
                <List>
                    {menus.map((item) => (
                        <DrawerMenuItem
                            href={item.href}
                            key={Math.random()}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
            <Button
                onClick={handleLogout}
                style={{
                    width: '100px',
                    marginLeft: '13px',
                    marginBottom: '8px',
                    padding: '6px 20px 6px 20px',
                    background: '#a0a3a0',
                }}
            >
                <LogoutIcon />
                <Typography style={{ marginLeft: 3 }} variant="h4">
                    Гарах
                </Typography>
            </Button>
        </Box>
    );
};

DrawerMenu.propTypes = {
    handleOpenAndClose: PropTypes.func,
    isOpen: PropTypes.bool,
    isMobile: PropTypes.bool,
};

// DrawerMenu.defaultProps = {
//     isOpen: false,
//     isMobile: false,
// };

export default DrawerMenu;
